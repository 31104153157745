<template>
    <div class="home_container" :class="isfilter?'hide':''">
        <header class="my_header" :class="issearch==true?'noblocked':'blocked'">
            <div class="header_container white_color">
                <div class="header_left">
                    <i class="header_l_svg" aria-hidden="true" @click="goBack"></i>
                </div>
                <div class="header_search">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" v-model="inputValue" placeholder="请输入商品搜索优惠券" class="white_color" @focus="openSearch">
                    <span class="clear_open" v-if="inputValue">
                        <img src="../assets/clear.png" alt="" @click="clearOpen">
                    </span>
                </div>
                <div class="header_right">
                    <i class="header_r_svg" aria-hidden="true">
                        <img src="../assets/refresh.png" alt="" :class="isrotate==true?'rotate_img':''" @click="refreshRotate">
                    </i>
                </div>
            </div>
        </header>
        <footer class="my_footer">
            <div class="footer_container white_color">
                <div class="weui-tabbar">
                    <router-link to="home" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_home">
                        </div>
                        <p class="weui-tabbar__label">首页</p>
                    </router-link>
                    <router-link to="classify" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_list">
                        </div>
                        <p class="weui-tabbar__label">分类</p>
                    </router-link>
                    <div class="weui-tabbar__item weui-bar__item--on">
                        <div class="weui-tabbar__icon foot_menu_search">
                        </div>
                        <p class="weui-tabbar__label">发现</p>
                    </div>
                    <router-link to="mine" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_mine">
                        </div>
                        <p class="weui-tabbar__label">我的</p>
                    </router-link>
                </div>
            </div>
        </footer>
        <div class="main_container" :class="issearch==true?'noblocked':'blocked'">
            <div class="top_container" :class="isfixed==true?'fixed':''">
                <!-- <div class="platform_container">
                    <ul class="platform">
                        <li @click="changePlatform(index)" :key="item.id" v-for="(item,index) in platforms" :class="selectPlatform==index?'active':''">{{ item }}</li>
                    </ul>
                </div>
                <div class="blank_row"></div> -->
                <div class="filter_container">
                    <div @click="selectCoupon" class="filter_left" :class="selectCouponFlag==true?'selected':''">优惠券
                    </div>
                    <div class="filter_middle">
                        <ul class="filter_item">
                            <li @click="changeFilterItem(index, item.sort)" :key="item.id" v-for="(item,index) in filterItems" :class="selectFilterItem==index?'selected':''">{{ item.label }}</li>
                        </ul>
                        <div class="filter" @click="changeFilter">筛选<i class="fa fa-filter" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
            <div class="goods_container">
                <ul class="goods">
                    <div class="goods_loading_gif" :class="isgoodsloading?'blocked':'noblocked'">
                        <img src="../assets/loading.gif" alt="">
                    </div>
                    <li :key="item.id" v-for="item in goodsItems" class="animated fadeInUp" :class="isgoodsloading?'noblocked':'blocked'">
                        <router-link :to="{ name: 'detail', query: { goodsId: item.goodsId, id: item.iid } }">
                            <div class="good_img">
                                <img :src="item.src" alt="">
                            </div>
                            <div class="good_info">
                                <div class="platform_img">
                                    <img class="platform_icon" src="../assets/tmicon.png" alt="" v-if="item.shoptype === 1">
                                    <img class="platform_icon" src="../assets/tbicon.png" alt="" v-if="item.shoptype === 0">
                                    {{ item.title }}
                                </div>
                                <div class="good_num">
                                    <div class="coupon" v-if="item.coupon_num">
                                        <span class="coupon_left">券</span>
                                        <span class="coupon_right">{{ item.coupon_num }}元</span>
                                    </div>
                                    <span class="float_right">月销{{ item.sell_num }}件</span>
                                </div>
                                <div class="discount_money" v-if="item.coupon_num">
                                    <p class="float_right store_name">{{ item.store }}</p>
                                    券后
                                    <span>
                                        <i>￥</i>
                                        {{ item.new_price }}
                                    </span>
                                </div>
                                <div class="discount_money" v-if="!item.coupon_num">
                                    <p class="float_right store_name">{{ item.store }}</p>
                                    折后
                                    <span>
                                        <i>￥</i>
                                        {{ item.new_price }}
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="loading" :class="isblank?'noblocked':'inline'">加载中,请稍候..
            </div>
            <div class="loading" :class="isblank?'inline':'noblocked'">加载完成，无更多数据
            </div>
        </div>
        <div class="search_container" :class="issearch==true?'blocked':'noblocked'">
            <div class="search">
                <div class="search_left">
                    <img @click="closeSearch" class="search_img" src="../assets/goback.png" alt="">
                </div>
                <div class="search_area">
                    <input v-focus v-model="inputValue" type="text" placeholder="请输入商品搜索优惠券" @keydown.enter="Searching" @input="Suggest" v-on:compositionend="cpLock = false" v-on:compositionstart="cpLock = true">
                    <span class="clear_input" v-if="inputValue">
                        <img src="../assets/clear.png" alt="" @click="clearInput">
                    </span>
                </div>
                <div class="search_right">
                    <img class="search_img" src="../assets/search.png" alt="" @click="Searching">
                </div>
            </div>
            <div class="suggest" :class="inputValue?'blocked':'noblocked'">
                <ul>
                    <li :key="item.id" v-for="item in suggestItem" @click="searchSuggest(item.kw)">{{ item.kw }}</li>
                </ul>
            </div>
            <div class="history" v-if="showHistory.length > 0">
                <div class="history_top">
                    <span class="history_title">历史记录</span>
                    <span class="history_delete">
                        <img src="../assets/delete.png" alt="" @click="changeDialog">
                    </span>
                </div>
                <div class="history_bottom">
                    <ul>
                        <li class="history_item" :key="item.id" v-for="item in showHistory"  @click="searchSuggest(item)">{{ item }}</li>
                    </ul>
                </div>
            </div>
            <div class="history">
                <div class="history_top">
                    <span class="history_title">热门推荐</span>
                    <span class="history_delete">
                        <img src="../assets/refresh.png" alt="" :class="isrotate==true?'rotate_img':''" @click="changeRotate">
                    </span>
                </div>
                <div class="history_bottom" :class="isrotate==true?'':'tada animated infinite'">
                    <ul class="show_style">
                        <li class="history_item" :key="item.id" v-for="item in showHot" @click="searchSuggest(item)">{{ item }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div :class="isfilter?'select_filter slide_out':''">
            <div class="select_filter_left" :class="isfilter?'blocked':'noblocked'" @click="changeFilter"></div>
            <div class="select_filter_right" :class="isfilter?'blocked':'noblocked'">
                <div class="filter_head">筛选</div>
                <div class="filter_service">
                    <div class="filter_service_title">
                        <span>标签</span>
                    </div>
                    <ul>
                        <li class="filter_service_item" @click="changeTab(index)" :key="item.id" v-for="(item,index) in filterList.tab" :class="selectFilterId === index?'select_active':''">{{ item.label }}</li>
                    </ul>
                </div>
                <div class="filter_service">
                    <div class="filter_service_title">
                        <span>排序</span>
                    </div>
                    <ul>
                        <li class="filter_service_item" @click="changeSort(index)" :key="item.id" v-for="(item,index) in filterList.tab[this.selectFilterId].sort" :class="filterList.tab[selectFilterId].default_sind === index?'select_active':''">{{ item.s_label }}</li>
                    </ul>
                </div>
                <div class="filter_service">
                    <div class="filter_service_title">
                        <span>商品</span>
                    </div>
                    <ul>
                        <li class="filter_service_item" @click="changeCoupon(index)" :key="item.id" v-for="(item,index) in filterList.coupon" :class="filterList.default_cind === index?'select_active':''">{{ item.c_label }}</li>
                    </ul>
                </div>
                <div class="filter_money">
                    <div class="filter_service_title">
                        <span>价格区间（元）</span>
                    </div>
                    <div class="filter_money_range">
                        <input type="number" placeholder="最低价" v-model="filterList.pricelowerlimit">
                        <span>-</span>
                        <input type="number" placeholder="最高价" v-model="filterList.priceupperlimit">
                    </div>
                </div>
                <div class="filter_foot">
                    <div class="filter_reset" @click="resetFilterList">重置</div>
                    <div class="filter_confirm" @click="doFilterSearch">确认</div>
                </div>
            </div>
        </div>
        <!-- <div class="loading_gif" :class="isloading?'blocked':'noblocked'">
            <img src="../assets/loading.gif" alt="">
        </div> -->
        <div class="back_top" :class="isblock==true?'blocked':'noblocked'" @click="backTop"></div>
        <div :class="isdialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <div class="icon-box">
                        <i class="weui-icon-warn weui-icon_msg"></i>
                        <div class="icon-box-info">确认清除历史记录？</div>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="changeDialog">取消</a>
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="clearHistory">确认</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        window.addEventListener('scroll', this.watchScroll)
    },
    destroyed () {
        window.removeEventListener('scroll', this.watchScroll)
    },
    created () {
        const { historyItems } = localStorage
        if (historyItems !== undefined) {
            this.showHistory = historyItems.split('|')
            if (this.showHistory.length > 15) {
                this.showHistory = this.showHistory.slice(0, 15)
            }
        }
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.$route.query.title) {
            this.issearch = false
            this.$nextTick(async function () {
            const { data: category } = await this.$http.get('goods/hotsearch')
            if (category.msg === '成功') {
                this.hotSearch = category.data.hotWords
                this.calcHot()
            }
            this.inputValue = this.$route.query.title
            this.src = 'goods/search'
            this.parameter = {
                type: 0,
                pageid: 1,
                pagesize: 20,
                keywords: this.inputValue,
                sort: 0,
                pricelowerlimit: '',
                priceupperlimit: '',
                hascoupon: 1
            }
            this.doSearch()
            })
        } else {
            this.$nextTick(async function () {
            const { data: category } = await this.$http.get('goods/hotsearch')
            if (category.msg === '成功') {
                this.hotSearch = category.data.hotWords
                this.calcHot()
            }
            this.src = 'goods/search'
            this.parameter = {
                type: 0,
                pageid: 1,
                pagesize: 20,
                keywords: '',
                sort: 0,
                pricelowerlimit: '',
                priceupperlimit: '',
                hascoupon: 1
            }
            this.doSearch()
        })
        }
    },
    activated () {
        this.iskeep = true
        window.scrollTo(0, this.initSearchscroll)
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        } else {
            this.islogin = false
            this.userInfo = {}
        }
    },
    deactivated () {
        this.iskeep = false
        this.initSearchscroll = this.scrollTop
    },
    watch: {
        $route (to, from) {
            if (to.fullPath !== '/search' && to.name === 'search' && to.query.title !== this.inputValue) {
                const { historyItems } = localStorage
                if (historyItems !== undefined) {
                    this.showHistory = historyItems.split('|')
                    if (this.showHistory.length > 15) {
                        this.showHistory = this.showHistory.slice(0, 15)
                    }
                }
                this.goodsItems = []
                this.isgoodsloading = true
                this.isloading = true
                this.issearch = false
                this.inputValue = this.$route.query.title
                this.src = 'goods/search'
                this.parameter = {
                    type: 0,
                    pageid: 1,
                    pagesize: 20,
                    keywords: this.inputValue,
                    sort: 0,
                    pricelowerlimit: '',
                    priceupperlimit: '',
                    hascoupon: 1
                }
                this.doSearch()
            }
        }
    },
    data () {
        return {
            initSearchscroll: 0,
            isbacktop: false,
            iskeep: true,
            isdialog: false,
            islogin: false,
            userInfo: {},
            cpLock: false,
            src: null,
            parameter: null,
            isblank: false,
            isgoodsloading: true,
            isloading: true,
            isfilter: false,
            isrotate: false,
            inputValue: '',
            issearch: true,
            suggestItem: [],
            hotSearch: [],
            showHot: [],
            showHistory: [],
            // isfixed
            isfixed: false,
            // isblock
            isblock: false,
            // scrollTop
            scrollTop: 0,
            selectCouponFlag: true,
            // platforms: ['', '', '淘宝天猫', '', ''],
            // selectPlatform: 2,
            filterItems: [{
                label: '综合',
                sort: 0
            }, {
                label: '销量',
                sort: 1
            }, {
                label: '价格',
                sort: 6
            }, {
                label: '比例',
                sort: 4
            }],
            selectFilterItem: 0,
            goodsItems: [],
            selectFilterId: 0,
            filterList: {
                tab: [{
                    label: '综合',
                    default_sind: 0,
                    sort: [{
                    s_label: '默认排序',
                    s_sort: 0
                }]
                }, {
                    label: '销量',
                    default_sind: 1,
                    sort: [{
                    s_label: '升序',
                    s_sort: 2
                }, {
                    s_label: '降序',
                    s_sort: 1
                }]
                }, {
                    label: '价格',
                    default_sind: 0,
                    sort: [{
                    s_label: '升序',
                    s_sort: 6
                }, {
                    s_label: '降序',
                    s_sort: 5
                }]
                }, {
                    label: '比例',
                    default_sind: 1,
                    sort: [{
                    s_label: '升序',
                    s_sort: 3
                }, {
                    s_label: '降序',
                    s_sort: 4
                }]
                }],
                default_cind: 0,
                coupon: [{
                    c_label: '有券',
                    hascoupon: 1
                }, {
                    c_label: '全部',
                    hascoupon: ''
                }],
                pricelowerlimit: '',
                priceupperlimit: ''
            }
        }
    },
    methods: {
        doFilterSearch () {
            this.isfilter = !this.isfilter
            this.selectFilterItem = this.selectFilterId
            this.filterItems[this.selectFilterItem].sort = this.filterList.tab[this.selectFilterId].sort[this.filterList.tab[this.selectFilterId].default_sind].s_sort
            if (this.filterList.default_cind === 1) {
                this.selectCouponFlag = false
            } else {
                this.selectCouponFlag = true
            }
            this.isgoodsloading = true
            this.isloading = true
            this.goodsItems = []
            this.parameter = {
                type: 0,
                pageid: 1,
                pagesize: 20,
                keywords: this.inputValue,
                sort: this.filterList.tab[this.selectFilterId].sort[this.filterList.tab[this.selectFilterId].default_sind].s_sort,
                pricelowerlimit: this.filterList.pricelowerlimit,
                priceupperlimit: this.filterList.priceupperlimit,
                hascoupon: this.filterList.coupon[this.filterList.default_cind].hascoupon
            }
            this.doSearch()
        },
        resetFilterList () {
            this.selectFilterId = this.selectFilterItem
            if (!this.selectCouponFlag) {
                this.filterList.default_cind = 1
            } else {
                this.filterList.default_cind = 0
            }
            this.filterList.tab[0].default_sind = 0
            this.filterList.tab[1].default_sind = 1
            this.filterList.tab[2].default_sind = 0
            this.filterList.tab[3].default_sind = 1
            this.filterList.pricelowerlimit = ''
            this.filterList.priceupperlimit = ''
            this.parameter.pricelowerlimit = ''
            this.parameter.priceupperlimit = ''
        },
        changeCoupon (index) {
            this.filterList.default_cind = index
        },
        changeSort (index) {
            this.filterList.tab[this.selectFilterId].default_sind = index
        },
        changeTab (index) {
            this.selectFilterId = index
        },
        changeFilter () {
            this.isfilter = !this.isfilter
            this.selectFilterId = this.selectFilterItem
            if (!this.selectCouponFlag) {
                this.filterList.default_cind = 1
            } else {
                this.filterList.default_cind = 0
            }
        },
        goBack () {
            this.$router.go(-1)
        },
        // changePlatform (index) {
        //     this.selectPlatform = index
        //     this.isgoodsloading = true
        //     setTimeout(() => {
        //         this.isgoodsloading = false
        //     }, 1000)
        // },
        changeFilterItem (index, sort) {
            if (index === this.selectFilterItem) {
                return
            }
            this.selectFilterItem = index
            this.isgoodsloading = true
            this.isloading = true
            this.goodsItems = []
            if (!this.inputValue) {
                this.parameter.keywords = ''
            }
            this.parameter.sort = sort
            this.doSearch()
        },
        selectCoupon () {
            this.selectCouponFlag = !this.selectCouponFlag
            if (this.selectCouponFlag) {
                this.parameter.hascoupon = 1
            } else {
                this.parameter.hascoupon = ''
            }
            this.goodsItems = []
            this.isloading = true
            this.isgoodsloading = true
            this.doSearch()
        },
        watchScroll () {
            const that = this
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const scrollHeight = document.body.scrollHeight
            const clientHeight = document.body.clientHeight
            const scrllBottom = scrollHeight - clientHeight - scrollTop
            if (scrllBottom < 100 && !this.isloading && !this.isblank && this.iskeep) {
                this.isloading = true
                this.parameter.pageid += 1
                this.doSearch()
            }
            if (scrollTop > 100) {
                that.isblock = true
            } else {
                that.isblock = false
            }
            if (scrollTop > 45 && scrollTop > that.scrollTop) {
                that.isfixed = false
                that.scrollTop = scrollTop
            } else if (scrollTop > 45 && scrollTop < that.scrollTop && !this.isbacktop) {
                that.isfixed = true
                that.scrollTop = scrollTop
            } else {
                that.isfixed = false
                that.scrollTop = scrollTop
            }
        },
        // backTop
        backTop () {
            const that = this
            this.isbacktop = true
            const timer = setInterval(() => {
                const ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                    this.isbacktop = false
                }
            }, 16)
        },
        closeSearch () {
            this.inputValue = this.parameter.keywords
            this.issearch = false
        },
        openSearch () {
            this.issearch = true
            this.Suggest()
        },
        changeRotate () {
            this.isrotate = true
            this.calcHot()
            setTimeout(() => {
                this.isrotate = false
            }, 1000)
        },
        refreshRotate () {
            window.scrollTo(0, 0)
            this.isrotate = true
            this.isgoodsloading = true
            this.isloading = true
            this.goodsItems = []
            this.doSearch()
            setTimeout(() => {
                this.isrotate = false
            }, 1000)
        },
        Searching () {
            this.parameter.keywords = this.inputValue
            this.issearch = false
            this.isgoodsloading = true
            this.isloading = true
            this.goodsItems = []
            this.doSearch()
        },
        Suggest () {
            if (this.cpLock) {
                return true
            }
            this.suggestItem = []
            if (this.inputValue !== null && this.inputValue !== '') {
                this.$nextTick(async function () {
                const { data: res } = await this.$http.post(
                    'goods/suggestion',
                    {
                        keywords: this.inputValue,
                        type: 3
                    })
                if (res.msg === '成功') {
                    this.suggestItem = res.data
                }
            })
            }
        },
        calcHot () {
            this.showHot = []
            var hotword = ''
            for (var i = 0; i < 15; i++) {
                hotword = this.hotSearch[parseInt(Math.random() * 100)]
                if (this.showHot.indexOf(hotword) === -1 && hotword) {
                    this.showHot.push(hotword)
                } else {
                    i -= 1
                }
            }
        },
        searchSuggest (keyword) {
            this.inputValue = keyword
            this.Searching()
        },
        doSearch () {
            this.isblank = false
            this.setSearchHistory(this.parameter.keywords)
            this.$nextTick(async function () {
                const { data: res } = await this.$http.post(this.src, this.parameter)
                if (res.msg === '成功') {
                    if (res.data.list) {
                        if (res.data.list.length < this.parameter.pagesize) {
                            this.isblank = true
                        }
                        res.data.list.forEach((i, index) => {
                            if (!i.goodsId | i.goodsId === -1) {
                                i.goodsId = ''
                            }
                            if (!i.id | i.id === -1) {
                                i.id = ''
                            }
                            this.goodsItems.push({
                                src: i.mainPic,
                                shoptype: i.shopType,
                                title: i.title,
                                new_price: i.actualPrice,
                                sell_num: i.monthSales,
                                coupon_num: i.couponPrice,
                                store: i.shopName,
                                iid: i.id,
                                goodsId: i.goodsId
                        })
                    })
                    } else {
                        if (res.data.length < this.parameter.pagesize) {
                            this.isblank = true
                        }
                        res.data.forEach((i, index) => {
                            if (!i.goodsId | i.goodsId === -1) {
                                i.goodsId = ''
                            }
                            if (!i.id | i.id === -1) {
                                i.id = ''
                            }
                            this.couponList.push({
                                src: i.mainPic,
                                shoptype: i.shopType,
                                title: i.title,
                                new_price: i.actualPrice,
                                sell_num: i.monthSales,
                                coupon_num: i.couponPrice,
                                store: i.shopName,
                                iid: i.id,
                                goodsId: i.goodsId
                            })
                        })
                    }
                } else {
                    this.isblank = true
                }
                this.isloading = false
                this.isgoodsloading = false
            })
        },
        setSearchHistory (kw) {
            if (kw) {
                kw = kw.trim()
                let { historyItems } = localStorage
                if (historyItems === undefined) {
                    localStorage.historyItems = kw
                    this.showHistory.push(kw)
                } else {
                    const onlyItem = historyItems.split('|').filter(e => e !== kw)
                    if (onlyItem.length > 0) historyItems = kw + '|' + onlyItem.join('|')
                    localStorage.historyItems = historyItems
                    this.showHistory = historyItems.split('|')
                    if (this.showHistory.length > 15) {
                        this.showHistory = this.showHistory.slice(0, 15)
                    }
                }
            }
        },
        changeDialog () {
            this.isdialog = !this.isdialog
        },
        clearHistory () {
            localStorage.removeItem('historyItems')
            this.showHistory = []
            this.isdialog = !this.isdialog
        },
        clearInput () {
            this.inputValue = ''
        },
        clearOpen () {
            this.issearch = true
            this.inputValue = ''
        }
    }
}
</script>

<style lang="less" scoped>
.ban {
    pointer-events: none;
    color: #bbb;
}
// header
.header_r_svg {
    margin-top: 0;
}
.header_r_svg img {
    width: 80%;
    height: 80%;
}
.header_l_svg {
    margin-top: 13px;
    background: url("../assets/head-return.svg") no-repeat;
}
// main_container
.main_container {
    margin-top: 45px;
    width: 100%;
    background-color: #EEEEEE;
}
// top_container
.blank_row {
    width: 100%;
    height: 3px;
}
.top_container {
    width: 100%;
    overflow: hidden;
    background: #fff;
}
.platform_container {
    width: 100%;
    height: 40px;
    font-size: 13px;
    text-align: center;
    line-height: 35px;
    border-bottom: 1px solid #f2f2f5;
}
.platform {
    display: inline-block;
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #f2f2f5;
}
.platform li {
    height: 40px;
    width: 20%;
    line-height: 35px;
    float: left;
}
.active {
    color: red;
    font-weight: bold;
    border-bottom: 2px solid red;
}
.filter_container {
    position: relative;
    text-align: center;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
    width: 100%;
}
.filter_left {
    margin: 0 10px;
    border-radius: 5px;
    position: absolute;
    float: left;
    width: 15%;
}
.filter_middle {
    margin-left: 20%;
    position: relative;
    width: 80%;
}
.filter_item {
    margin-left: 10px;
    width: 80%;
}
.filter_item li {
    width: 20%;
    float: left;
    text-align: center;
    border-radius: 5px;
}
.filter {
    float: right;
    margin-right: 20px;
}
.selected {
    color: #fff;
    background: #FF4400;
}
// goods_container
.goods_container {
    width: 100%;
    overflow: hidden;
}
.goods li {
    padding: 10px;
    -webkit-transition: all .4s ease 0s;
    transform-origin: center;
    background: #fff;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    zoom: 1;
    font-family: Arial, Helvetica, sans-serif;
}
.goods li::before {
    content: '';
    display: block;
}
.goods li::after {
    content: '';
    display: block;
}
.good_img {
    position: relative;
    width: 92px;
    height: 92px;
    float: left;
}
.good_img img {
    background-color: #f5f5f5;
    display: block;
    width: 100%;
    height: 100%;
}
.good_info {
    height: 92px;
    margin-left: 105px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    zoom: 1;
    top: 5.6px;
}
.platform_img {
    line-height: 19.6px;
    font-size: 13px;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 39px;
    margin-right: 10px;
    margin-bottom: 0;
    overflow: hidden;
    color: #333;
}
.platform_icon {
    width: 13px;
    height: 13px;
    vertical-align: middle;
    margin-right: 3px;
    margin-bottom: 2px;
    border-radius: 3px;
    border: 0;
}
.good_num {
    padding-top: 0;
    font-size: 12px;
    color: #aaa;
    line-height: 21px;
    padding-right: 10px;
}
.coupon {
    border: 1px solid #f50;
    display: inline;
    font-size: 12px;
    border-radius: 3px;
    height: 14px;
    line-height: 14px;
    overflow: hidden;
    margin-right: 12px;
    position: relative;
}
.coupon_left:nth-child(1) {
    z-index: 1;
    zoom: 1;
    display: inline-block;
}
.coupon_left {
    left: 0;
    top: 0;
    width: 16px;
    height: 14px;
    color: #fff;
    background: #f50;
    text-align: center;
    padding-right: 0px !important;
    margin-right: 0px !important;
    position: absolute !important;
}
.coupon_right {
    color: #f50;
    display: inline-block;
    padding-right: 5px;
    padding-left: 20px;
}
.discount_money {
    font-size: 12px;
    height: 24px;
    color: #999;
    margin-top: 20px;
}
.store_name {
    font-size: 10px;
    height: 14px;
    color: #a08f8f;
    max-width: 60%;
    display: -webkit-box;
    line-height: 14px;
    overflow: hidden;
    position: absolute;
    bottom: 9.5px;
    right: 10px;
}
.discount_money span {
    font-weight: 550;
    font-size: 15px;
    color: #444;
}
.discount_money span i {
    font-size: 12px;
    font-style: normal;
}
// search_container
.clear_open {
    position: absolute;
    top: 0;
    right: 5%;
    img {
        width: 15px;
        vertical-align: middle;
    }
}
.clear_input {
    position: absolute;
    top: 0;
    right: 20%;
    img {
        width: 15px;
        vertical-align: middle;
        margin-bottom: 2px;
    }
}
.search_container {
    width: 100%;
    max-width: 640px;
    top: 0;
    position: fixed;
}
.search {
    background: #fff;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 13px;
}
.search_left {
    float: left;
    padding: 0 0 0 15px;
}
.search_area {
    width: auto;
    display: inline-block;
}
.search_right {
    float: right;
    padding: 0 15px 0 0;
}
.search_img {
    width: 18px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.search_area input {
    background: rgba(0,0,0,0.05);
    color: #999;
    border-radius: 3px;
    padding: 0 36px 0 22px;
    width: 280px;
    margin: 6px 0 0 0;
    list-style-type: none;
    height: 30px;
    text-align: center;
    border: 0;
    font-size: 13px;
    line-height: 15px;
    outline: none;
    position: relative;
}
// history
.history {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
}
.history_top {
    padding-bottom: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.history_title {
    color: #666;
    overflow: hidden;
    padding-right: 10px;
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 10%;
    display: block;
}
.history_delete img {
    width: 14px;
    vertical-align: middle;
    margin-right: 25px;
}
.history_bottom {
    width: 100%;
    padding: 0 10px 10px 10px;
    margin-left: 3px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.history_bottom ul {
    width: 100%;
    overflow: hidden;
}
.history_item {
    width: auto;
    float: left;
    text-align: center;
    color: #888;
    background: #fff;
    margin: 0.44rem 0.21rem;
    font-size: 11px;
    border-radius: 3px;
    padding: 0px 10px;
    max-width: 30%;
    overflow: hidden;
    height: 28px;
    line-height: 28px;
    background: #eee !important;
    font-family: Arial, Helvetica, sans-serif;
}
// filter_select
.select_filter {
    position: fixed;
    left: 88%;
    top: 0;
    z-index: 1000;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.slide_out {
    -webkit-transform: translate3d(-88vw,0,0);
    transform: translate3d(-88vw,0,0);
    -webkit-transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease,-webkit-transform .4s ease;
}
.select_filter_left {
    width: 20%;
    height: 100%;
    float: left;
}
.select_filter_right {
    margin-left: 20%;
    width: 80%;
    height: 100%;
    background: #fff;
    overflow: hidden;
}
.filter_head {
    height: 40px;
    top: 0;
    width: 100%;
    text-align: center;
    line-height: 40px;
    background: #eee;
    font-size: 14px;
    opacity: 1;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease;
    border-bottom-width: 1px;
    color: rgba(0, 0, 0, 0.3);
}
.filter_service {
    width: 100%;
    margin-top: 10px;
    background: #fff;
}
.filter_service_title {
    position: relative;
    display: flex;
    color: rgba(0, 0, 0, 0.75);
}
.filter_service_title span {
    overflow: hidden;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    flex-basis: 10%;
    font-size: 15px;
}
.filter_service ul {
    width: 100%;
    padding: 0 10px 10px 10px;
    margin-left: 5px;
    margin-top: 5px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.filter_service_item {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin: 6px 3px;
    color: #4a4a4a;
    padding: 5px 5px;
    font-size: 11px;
    border-radius: 3px;
    background: #eee;
}
.filter_money {
    width: 100%;
    margin-top: 10px;
}
.filter_money_range {
    width: 100%;
    padding: 0 10px 10px 10px;
    margin-left: 5px;
    margin-top: 10px;
}
.filter_money_range input {
    display: inline-block;
    width: 100px;
    text-align: center;
    font-size: 13px;
    height: 30px;
    background: #fafafa;
    border: 0;
}
.filter_money_range span {
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 0;
    flex-basis: 0;
    text-align: center;
    height: 25px;
    line-height: 2px;
    color: #999;
}
.filter_foot {
    position: fixed;
    margin: 0 auto;
    left: 20%;
    bottom: 10%;
    right: 0;
    width: 60%;
    height: 36px;
}
.filter_reset {
    height: 100%;
    width: 50%;
    text-align: center;
    background: #eee;
    color: #666;
    padding: 7px 0;
    border-radius: 100px 0px 0px 100px;
    float: left;
}
.filter_confirm {
    margin-left: 50%;
    width: 50%;
    height: 100%;
    text-align: center;
    background: linear-gradient(to right,#EF8833,#EC5F2A);
    padding: 7px 0;
    border-radius: 0px 100px 100px 0px;
}
.select_active {
    background: #EC5F2A;
    color: #fff;
}
.goods_loading_gif {
    width: 100%;
    max-width: 640px;
    position: fixed;
    background: #fff;
    text-align: center;
}
.goods_loading_gif img {
    margin-top: 30%;
}
// suggest
.suggest {
    background: rgb(238, 238, 238);
    z-index: 999;
    overflow: hidden;
    position: fixed;
    width: 100%;
}
.suggest ul {
    padding-left: 0;
    display: block;
}
.suggest li {
    padding: 8px 15px;
    background: #fff;
    margin-bottom: 1px;
    color: #999;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
}
</style>
